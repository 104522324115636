.accordion{
    border: 0 !important;
}
  
  @media screen and (max-width: 991px) {
    .accordion {
      margin-top: 0px;
    }
  }
  
  .accordion.accordion2 {
    margin-top: 0;
  }
  
  .accordion-button {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    padding: 10px 40px;
    font-weight: 700;
    line-height: 1.5;
    text-align: left;
    font-size: 16px;
    border: 0;
    border-radius: 15px;
    overflow-anchor: none;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
  }
  
  @media screen and (max-width: 767px) {
    .accordion-button {
      padding: 19px 19px;
    }
  }
  
  @media screen and (max-width: 767px) {
    .accordion-button {
      font-size: 16px;
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  
  .accordion-button.collapsed {
    border-bottom-width: 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  
  .accordion-button:not(.collapsed) {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  
  .accordion-button:not(.collapsed) span {
    background: -webkit-gradient(linear, left top, right top, from(#fc7318), to(#feb423));
    background: -webkit-linear-gradient(left, #fc7318 0%, #feb423 100%);
    background: -o-linear-gradient(left, #fc7318 0%, #feb423 100%);
    background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
    -webkit-background-clip: text;
  }
  
  .accordion-button:not(.collapsed)::after {
    font-weight: 700;
    font-size: 16px;
    content: "-";
    background-image: none;
    -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
            transform: rotate(0deg);
    color: #feb423;
  }
  
  .accordion-button::after {
    background-image: none;
    font-weight: 700;
    font-size: 16px;
    content: "+";
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-text-fill-color: currentColor;
    background-color: white;
    -webkit-box-shadow: 0px 5px 8px 0px rgba(39, 66, 161, 0.03);
            box-shadow: 0px 5px 8px 0px rgba(39, 66, 161, 0.03);
    width: 28px;
    height: 28px;
    z-index: 1;
    border-radius: 50%;
  }
  
  .accordion-button:hover {
    z-index: 2;
  }
  
  .accordion-button:focus {
    z-index: 3;
    border-color: #8189b5;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(2, 18, 106, 0.25);
            box-shadow: 0 0 0 0 rgba(2, 18, 106, 0.25);
  }
  
  .accordion2 .accordion-button {
    padding: 18.5px 40px;
  }
  
  .accordion2 .accordion-button:not(.collapsed)::after {
    background: -webkit-gradient(linear, left top, right top, from(#fc7318), color-stop(#fe8415), color-stop(#fe9515), color-stop(#fea51a), to(#feb423));
    background: -webkit-linear-gradient(left, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
    background: -o-linear-gradient(left, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
    background: linear-gradient(to right, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
    border: solid 2px transparent;
    background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), -webkit-linear-gradient(349deg, #fc7318 0%, #feb423 100%);
    background-image: -o-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), -o-linear-gradient(349deg, #fc7318 0%, #feb423 100%);
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #fc7318 0%, #feb423 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    -webkit-box-shadow: 2px 1000px 1px #f7faff inset;
            box-shadow: 2px 1000px 1px #f7faff inset;
    color: #fc7418;
  }
  
  .accordion-header {
    margin-bottom: 0;
  }
  
  .accordion-item {
    background: #f7faff;
    margin-bottom: 10px;
    border-radius: 15px;
  }
  
  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-width: 0px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  
  .accordion-item:last-of-type .accordion-collapse {
    border-bottom-width: 0px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  
  .accordion-collapse {
    border: solid rgba(0, 0, 0, 0.125);
    border-width: 0 0px;
  }
  
  .accordion-body {
    padding: 10px 40px;
    padding-top: 0px;
    font-size: 14px;
    color: #000;
  }
  
  .accordion-flush .accordion-button {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }
  
  .accordion-flush .accordion-collapse {
    border-width: 0;
  }
  
  .accordion-flush .accordion-item:first-of-type .accordion-button {
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-width: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }





  .accordion-button {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 19px 40px;
    font-size: 0.9375rem;
    color: #02126a;
    text-align: left;
    background-color: #f7faff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    -webkit-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, border-radius 0.15s ease,
      -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      border-radius 0.15s ease;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      border-radius 0.15s ease;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  .accordion-button:not(.collapsed) {
    color: #02105f;
    background-color: #f7faff;
    -webkit-box-shadow: inset 0 0px 0 rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 0px 0 rgba(0, 0, 0, 0.125);
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2302105f'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .accordion-button::after {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2302126a'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button::after {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  .accordion-button:hover {
    z-index: 2;
  }
  .accordion-button:focus {
    z-index: 3;
    border-color: #8189b5;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(2, 18, 106, 0.25);
    box-shadow: 0 0 0 0 rgba(2, 18, 106, 0.25);
  }
  .accordion-header {
    margin-bottom: 0;
  }
  .accordion-item {
    background-color: #f7faff;
    border: 0px solid rgba(0, 0, 0, 0.125);
  }
  .accordion-item:first-of-type {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .accordion-item:not(:first-of-type) {
    border-top: 0;
  }
  .accordion-item:last-of-type {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  .accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  .accordion-body {
    padding: 19px 40px;
  }
  .accordion-flush .accordion-collapse {
    border-width: 0;
  }
  .accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }
  .accordion-flush .accordion-item:first-child {
    border-top: 0;
  }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0;
  }
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0;
  }



  @media screen and (max-width: 991px) {
    .accordion {
      margin-top: 0px;
    }
  }
  .accordion.accordion2 {
    margin-top: 0;
  }
  .accordion-button {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 19px 40px;
    font-weight: 700;
    line-height: 1.5;
    text-align: left;
    font-size: 18px;
    border: 0;
    border-radius: 15px;
    overflow-anchor: none;
    -webkit-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, border-radius 0.15s ease,
      -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      border-radius 0.15s ease;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      border-radius 0.15s ease;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
  }
  @media screen and (max-width: 767px) {
    .accordion-button {
      padding: 19px 19px;
    }
  }
  @media screen and (max-width: 767px) {
    .accordion-button {
      font-size: 16px;
    }
  }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  .accordion-button.collapsed {
    border-bottom-width: 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .accordion-button:not(.collapsed) {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .accordion-button:not(.collapsed) span {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#fc7318),
      to(#feb423)
    );
    background: -webkit-linear-gradient(left, #fc7318 0%, #feb423 100%);
    background: -o-linear-gradient(left, #fc7318 0%, #feb423 100%);
    background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .accordion-button:not(.collapsed)::after {
    font-weight: 700;
    font-size: 16px;
    content: "-";
    background-image: none;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    color: #feb423;
  }
  .accordion-button::after {
    background-image: none;
    font-weight: 700;
    font-size: 16px;
    content: "+";
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-text-fill-color: currentColor;
    background-color: white;
    -webkit-box-shadow: 0px 5px 8px 0px rgba(39, 66, 161, 0.03);
    box-shadow: 0px 5px 8px 0px rgba(39, 66, 161, 0.03);
    width: 28px;
    height: 28px;
    z-index: 1;
    border-radius: 50%;
  }
  .accordion-button:hover {
    z-index: 2;
  }
  .accordion-button:focus {
    z-index: 3;
    border-color: #8189b5;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(2, 18, 106, 0.25);
    box-shadow: 0 0 0 0 rgba(2, 18, 106, 0.25);
  }
  .accordion2 .accordion-button {
    padding: 18.5px 40px;
  }
  .accordion2 .accordion-button:not(.collapsed)::after {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#fc7318),
      color-stop(#fe8415),
      color-stop(#fe9515),
      color-stop(#fea51a),
      to(#feb423)
    );
    background: -webkit-linear-gradient(
      left,
      #fc7318,
      #fe8415,
      #fe9515,
      #fea51a,
      #feb423
    );
    background: -o-linear-gradient(
      left,
      #fc7318,
      #fe8415,
      #fe9515,
      #fea51a,
      #feb423
    );
    background: linear-gradient(
      to right,
      #fc7318,
      #fe8415,
      #fe9515,
      #fea51a,
      #feb423
    );
    border: solid 2px transparent;
    background-image: -webkit-linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      -webkit-linear-gradient(349deg, #fc7318 0%, #feb423 100%);
    background-image: -o-linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      -o-linear-gradient(349deg, #fc7318 0%, #feb423 100%);
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(101deg, #fc7318 0%, #feb423 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    -webkit-box-shadow: 2px 1000px 1px #f7faff inset;
    box-shadow: 2px 1000px 1px #f7faff inset;
    color: #fc7418;
  }
  .accordion-header {
    margin-bottom: 0;
  }
  .accordion-item {
    background: #f7faff;
    margin-bottom: 10px;
    border-radius: 15px;
  }
  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-width: 0px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  .accordion-item:last-of-type .accordion-collapse {
    border-bottom-width: 0px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  .accordion-collapse {
    border: solid rgba(0, 0, 0, 0.125);
    border-width: 0 0px;
  }
  .accordion-body {
    padding: 19px 40px;
    padding-top: 0px;
  }
  .accordion-flush .accordion-button {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }
  .accordion-flush .accordion-collapse {
    border-width: 0;
  }
  .accordion-flush .accordion-item:first-of-type .accordion-button {
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-width: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }


  /* //flip box  */
    /* Flip box container */
    .flip-box-container {
      margin-top: 50px;
      /* margin-bottom: 50px; */
      display: flex;
      justify-content: space-between;
    }
  
    /* Flip box */
    .flip-box {
      background-color: #f1f1f1;
      width: 1000px;
      height: 250px;
      perspective: 1000px;
      margin: 10px;
    }
  
    /* Flip box inner container */
    .flip-box-inner {
      width: 100%;
      height: 100%;
      transition: transform 0.5s;
      transform-style: preserve-3d;
    }
  
    /* Flip box when hovered */
    .flip-box:hover .flip-box-inner {
      transform: rotateY(180deg);
    }
  
    /* Flip box front and back */
    .flip-box-front,
    .flip-box-back {
      width: 100%;
      height: 100%;
      position: absolute;
      backface-visibility: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    /* Flip box front */
    .flip-box-front {
      background-color: #04326d;
    }
  
    /* Flip box back */
    .flip-box-back {
      background-color: #d8dce4;
      transform: rotateY(180deg);
      /* #ef9535----orange color  */
    }
    .flip-box-back ol {
      list-style-position: outside; /* Place the bullet points outside the list */
    }
  
    .flip-box-back li {
      list-style: circle; /* Use bullets (you can change 'disc' to 'circle' or 'square' for different bullet styles) */
      color: black;
      font-size: 17px;
      font-family: 'Barlow Semi Condensed' !important;  
    }
  
    /* Customize fonts and colors as needed */
    .flip-box h3 {
      color: #fff;
    }
    .logos {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 20px; */
  }

  .logo {
    max-width: 200px; /* Adjust the max-width of the logos as needed */
    /* margin: 10px; */
  }
  


  /* Add this CSS to your stylesheet */
.additional-info {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  padding: 20px;
  background-color: #f7faff;
  border-radius: 5px;
  text-align: center; /* Center the text within the section */
}

.additional-info p {
  margin: 0; /* Remove default margin for <p> elements inside additional-info */
}

.additional-info strong {
  font-weight: bold; /* Make the text within <strong> elements bold */
}

/* Style each line of additional info */
.additional-info p:not(:first-child) {
  margin-top: 10px; /* Add spacing between lines */
}

/* additional-info for sunsure  */
.additional-info1 {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  padding: 17px;
  background-color: #f7faff;
  border-radius: 5px;
  text-align: center; /* Center the text within the section */
}

.additional-info1 p {
  margin: 0; /* Remove default margin for <p> elements inside additional-info */
}

.additional-info1 strong {
  font-weight: bold; /* Make the text within <strong> elements bold */
}

/* Style each line of additional info */
.additional-info1 p:not(:first-child) {
  margin-top: 10px; /* Add spacing between lines */
}

/* additional-info for medkart  */
.additional-info2 {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  padding: 14px;
  background-color: #f7faff;
  border-radius: 5px;
  text-align: center; /* Center the text within the section */
}

.additional-info2 p {
  margin: 0; /* Remove default margin for <p> elements inside additional-info */
}

.additional-info2 strong {
  font-weight: bold; /* Make the text within <strong> elements bold */
}

/* Style each line of additional info */
.additional-info2 p:not(:first-child) {
  margin-top: 10px; /* Add spacing between lines */
}

/* additional-info for hrone  */
.additional-info3 {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  padding: 22px;
  background-color: #f7faff;
  border-radius: 5px;
  text-align: center; /* Center the text within the section */
}

.additional-info3 p {
  margin: 0; /* Remove default margin for <p> elements inside additional-info */
}

.additional-info3 strong {
  font-weight: bold; /* Make the text within <strong> elements bold */
}

/* Style each line of additional info */
.additional-info3 p:not(:first-child) {
  margin-top: 10px; /* Add spacing between lines */
}


/* Add this CSS to your stylesheet */
.abc {
  color: #35507a; /* Set the text color to #35507a */
}